<template>
  <span v-if="this.isCheckbox === true">

    <div class="form-check" :class="{error:v.$error, valid:!v.$invalid, required:v.$params.required}">
      <slot></slot>
      <div class="note">
        <slot name="note"></slot>
      </div>
      <div class="errors">
        <ul v-if="messages.length">
          <li v-for="message in messages">{{message}}</li>
        </ul>
      </div>
    </div>
  </span>
  <span v-else>
    <div class="form-group" :class="{error:v.$error, valid:!v.$invalid, required:v.$params.required}">
      <label>{{label}}</label>
      <slot></slot>
      <div class="note">
        <slot name="note"></slot>
      </div>
      <div class="errors">
        <ul v-if="messages.length">
          <li v-for="message in messages">{{message}}</li>
        </ul>
      </div>
    </div>
  </span>
</template>

<script>

  export default {
    name: 'validation-field',
    props: ['label', 'v','isCheckbox'],
    data() {
      return {}
    },
    computed: {
      messages() {
        let messages = [];
        if (this.v.$error) {
          let errorKeys = Object.keys(this.v.$params);

          for (let key in errorKeys) {
            if (this.v[errorKeys[key]] === false) {

              let params = {};
              let errorLabel = errorKeys[key];


              if (
                this.v.$params[errorKeys[key]] &&
                this.v.$params[errorKeys[key]].hasOwnProperty("type") &&
                this.v.$params[errorKeys[key]].type === errorKeys[key]
              ) {
                const k = errorKeys[key];

                if (this.v.$params[errorKeys[key]].hasOwnProperty(errorKeys[key])) {
                  params = {
                    [k]: this.v.$params[errorKeys[key]][errorKeys[key]]
                  };
                }

                if (this.v.$params[errorKeys[key]].type === 'sameAs') {

                  if (typeof this.v.$params[errorKeys[key]].eq === 'function') {

                    params = {
                      [k]: this.v.$params[errorKeys[key]][errorKeys[key]]
                    };

                    errorLabel = errorLabel + '_' + key;

                  } else if (typeof this.v.$params[errorKeys[key]].eq === 'string') {
                    errorLabel = errorLabel + '_' + key + "_" + this.v.$params[errorKeys[key]].eq;
                  }

                }
              } else {
                params = this.v.$params[errorKeys[key]];
              }

              messages.push(this.$t("validation_field_message." + errorLabel, params));
            }
          }
        }

        return messages;
      },
      model: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        }
      }
    },
    methods: {},
    mounted() {

    },
  };
</script>

<style lang="scss">
  @import "../../assets/scss/field.scss";
</style>

